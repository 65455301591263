import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
    UserGroupIcon,
  } from '@heroicons/react/20/solid'

  import DashboardImg from '../img/dashboard.png'
  
  const features = [
    {
      name: 'Dashboard',
      description: 'El corazón de tu negocio en un vistazo: Control total y visualización estratégica.',
      icon: CloudArrowUpIcon,
    },
    {
      name: 'Pedidos/Registros',
      description: 'Registra y gestiona, pedidos, ventas, arriendos etc, manteniendo un seguimiento impecable.',
      icon: LockClosedIcon,
    },
    {
      name: 'Reportes',
      description: 'Información valiosa en tus manos: Reportes detallados para tomar decisiones informadas.',
      icon: ArrowPathIcon,
    },
    {
      name: 'Seguridad',
      description: 'Tu tranquilidad es primordial: Garantizamos la máxima seguridad de tus datos.',
      icon: FingerPrintIcon,
    },
    {
      name: 'API.',
      description: 'Conéctate y expande: Integra tu sistema con otros servicios, plataformas o redes sociales. Somos Meta Developers!',
      icon: Cog6ToothIcon,
    },
    {
      name: 'Usuarios',
      description: 'Administra roles y permisos para un acceso controlado y personalizado.',
      icon: UserGroupIcon,
    },
  ]
  
  export default function Caracteristicas() {
    return (
      <div className="bg-white py-12 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Desarrollo de Software</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Nos sumergimos en tu negocio para ofrecer una solución eficaz</p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Centraliza tu información tu propia plataforma
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src={DashboardImg}
              alt="App screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
              width={2432}
              height={1442}
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
            </div>
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                  {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    )
  }
