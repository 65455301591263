import js from '../img/js.png';
import node from '../img/node.png';
import postgresql from '../img/postgresql.png';
import react from '../img/react.png';
import aws from '../img/aws.png'
import meta from '../img/meta.png';

function Skills() {
    const images = [js, node, postgresql, meta, react,  aws,];

    return (
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8 mb-32">
            <div className="flex flex-wrap justify-center items-center gap-8">
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Image ${index}`}
                        style={{
                            maxWidth: '80px',
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto',
                        }}
                    />
                ))}
            </div>
        </div>
    );
}

export default Skills;


