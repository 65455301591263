import { useState } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import Video from '../img/bg_v2.mp4';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]
const tiers = [
  {
    name: 'Web Corporativa',
    id: 'tier-freelancer',
    href: '#contacto',
    price: { monthly: '389.990', annually: '$144' },
    description: 'Potenciamos tu éxito con diseño web único y estratégico. ¡Conquista online, No verás sitios con nuestra misma tecnología :) !',
    features: ['One-page', '2 Correos corporativos'],
    mostPopular: false,
    ValorInicial: "Desde ",
    iva: "+ iva"
  },
  {
    name: 'Desarrollo de Software',
    id: 'tier-startup',
    href: '#contacto',
    price: { monthly: '0', annually: '$288' },
    iva: "",
    cotizacion: "Accede a tu información desde cualquier lugar del mundo.",
    tipo: "",
    description: 'Digitaliza procesos, reduce trabajo, centraliza tus datos y utilizalos a tu favor.',
    ValorInicial: "Desde ",
    features: [
      'Automatiza Procesos',
      'Integraciones con SII',
      'Integraciones de Pagos',
      'Servicios en la nube',
      'Integración con terceros',
    ],
    mostPopular: true,
  },
  {
    name: 'E-commerce',
    id: 'tier-enterprise',
    href: '#contacto',
    price: { monthly: '589.990', annually: '$576' },
    description: 'Transforma visitantes en clientes leales con nuestra solución e-commerce innovadora.',
    iva: "+ iva",
    features: [
      'Web Pay o Mercadopago',
      'Integracion ventas internacionales (LATAM Cross-Border)',
      'Productos Ilimitados',
      'Autoadministrable',
      'Capacitación',
    ],
    mostPopular: false,
    ValorInicial: "Desde "
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Servicios() {
  const [frequency, setFrequency] = useState(frequencies[0])

  return (
    <div className="relative bg-white py-12 sm:py-24">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 w-full h-full object-cover video-background"
      >
        <source src={Video} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Cotiza</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Desarrollos que se adaptan a tu negocio.
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">     
        Elige el servicio ideal para impulsar tu negocio, gestionar tu información, vender en línea o dar a conocer tu marca.
        </p>
    
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'relative rounded-3xl p-8 xl:p-10 bg-white bg-opacity-75'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    Digitaliza!
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>

              {tier.name === 'Desarrollo de Software' ? (
                <p className="mt-6 text-lg font-semibold text-gray-800">Agenda una reunión.</p>
              ) : (
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="px-2 text-4xl font-bold tracking-tight text-gray-900">{tier.price[frequency.value]}</span>
                  <p className="mt-1 text-sm text-gray-900">{tier.iva}</p>
                  <p className="mt-1 text-sm text-gray-600">{tier.tipo}</p>
                </p>
              )}

              <p className="mt-1 text-sm text-gray-600">{tier.cotizacion}</p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                Contactar!
              </a>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
